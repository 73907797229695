<template>
  <div class="photo-wall">
    <draggable v-model="mediaArr" class="wall-content" :disabled="!draggable" animation="500"
      draggable=".draggable">
      <div class="img-content draggable" v-for="(media,index) in mediaArr" :key="index" :style="{
          width: widthSize,
          height: heightSize,
          'border-radius': BorderRadius,
        }">
        <div v-if="typeof media === 'string'">
          <!--  :preview-src-list="mediaArr" -->
          <el-image class="photo" :style="{
            width: widthSize,
            height: heightSize,
            'border-radius': BorderRadius,
          }" :src="fixImageUrl(media)" :fit="fit"
          @click="handlePreview(index)"/>
          <div class="bg-mask" :style="{'line-height': heightSize}" v-if="showMask && !disabled">
            <p v-if="showCut" class="el-icon-scissors" @click.stop.prevent="handleCrop(media, index)"></p>
            <p class="el-icon-zoom-in" @click.stop.prevent="handlePreview(index)"/>
            <p class="el-icon-delete" @click.stop.prevent="deleteMediaSrc(index)"/>
          </div>
        </div>
        <div v-else>
          <div v-if="media.type === 'image'">
<!--
  :preview-src-list="mediaArr.map(m => m.type === 'image' && m.url)"
-->
            <el-image class="photo" :style="{
            width: widthSize,
            height: heightSize,
            'border-radius': BorderRadius,
          }" :src="fixImageUrl(media.url)" :fit="fit"
               @click="handlePreview(index)"     />
            <div class="bg-mask" :style="{'line-height': heightSize}" v-if="showMask && !disabled">
              <p v-if="showCut" class="el-icon-scissors" @click.stop.prevent="handleCrop(media.url, index)"></p>
              <p class="el-icon-zoom-in" @click.stop.prevent="handlePreview(index)"/>
              <p class="el-icon-delete" @click.stop.prevent="deleteMediaSrc(index)"/>
            </div>
          </div>
          <div v-if="media.type === 'video' || media.thumbnail">
            <el-image class="photo" :style="{
            width: widthSize,
            height: heightSize,
            'border-radius': BorderRadius,
          }" :src="fixImageUrl(media.thumbnail)" :fit="fit"/>
            <div class="video-mask" @click="handlePlay(media)">
              <img class="video-play" src="@/base/assets/images/media/video-play@2x.png"
                :style="{'line-height': heightSize}" />
            </div>
            <div v-if="!disabled" class="delete" @click.stop.prevent="deleteMediaSrc(index)">
              删除视频
            </div>
          </div>
        </div>
      </div>
      <!-- 添加图片按钮 -->
      <div v-if="limit && showAdd">
        <div :class="{disabled}" :style="{
          width: widthSize,
          height: heightSize,
          'border-radius': BorderRadius,
          border: '1px dashed #dcdfe6',
          'margin-bottom': 0,
        }" class="img-content add" @click="!disabled ? (show = true) : ''">
          <div class="add">
            <div class="add-content">
              <i class="el-icon-plus"/>
              <p class="info">{{ addText }}</p>
            </div>
          </div>
        </div>
        <slot name="info"/>
      </div>
    </draggable>
    <!-- 图片弹窗 -->
    <media-selector v-model="show" :max="limit" multiple :ratio="ratio" :type="UploadType" @select="handlePhoto">
    </media-selector>
    <!-- 视频弹窗 -->
    <VideoDialog />
    <!-- 裁剪弹窗 -->
<!--    <crop-dialog :config-api="'/admin/admin/media/requestUpload'" is-dialog />-->
  </div>
</template>
<script>
import draggable from "vuedraggable";
import VideoDialog from "../../../base/components/Preview/VideoViewer";
import MediaSelector from "./MediaSelector";
import {randomString} from '../../../base/utils/tool'
import CropDialog from '../../../base/components/Media/CropDialog' //视频弹窗
export default {
  name: "MediaWall",
  model: {
    prop: "mediaSrc",
    event: "handleMediaSrc",
  },
  provide() {
    return {
      mediaKey: this.mediaKey
    }
  },
  props: {
    //大小 px、%
    width: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    borderRadius: {
      type: [Number, String],
      default: 0,
    },
    UploadType: {
      type: [String, Array],
      default: "image",
    },
    //多媒体数组
    mediaSrc: {
      type: [Array, Object],
      required: true,
    },
    //图片如何适应容器框
    fit: {
      type: String,
      default: "cover",
    },
    //添加按钮文字提示
    addText: {
      type: String,
      default: "上传图片",
    },
    //是否显示蒙层
    showMask: {
      type: Boolean,
      default: true,
    },
    // 限制上传数量
    limit: {
      type: Number,
      default: 9,
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否使用拖拽排序
    draggable: {
      type: Boolean,
      default: false,
    },

    ratio: [String, Array],

    showCut: {
      type: Boolean,
      default: false
    },

    showAdd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mediaKey: randomString(),
      show: false, //显示图片弹窗
      currentIndex: 0, //从第几张图片开始预览
      VideoData: {}, // 当前播放视频数据
    };
  },
  computed: {
    widthSize() {
      if (typeof this.width === "number") {
        return this.width + "px";
      } else {
        return this.width;
      }
    },
    heightSize() {
      if (typeof this.height === "number") {
        return this.height + "px";
      } else {
        return this.height;
      }
    },
    /**
     * @return {string}
     */
    BorderRadius() {
      if (typeof this.borderRadius === "number") {
        return this.borderRadius + "px";
      } else {
        return this.borderRadius;
      }
    },
    srcList() {
      if (this.mediaSrc.length) {
        if (this.mediaSrc[0].type === "image" && this.mediaSrc[0].url) {
          return this.mediaSrc.map((item) => item.url);
        }
      }
      return this.mediaSrc;
    },
    mediaArr: {
      get() {
        return this.mediaSrc;
      },
      set(val) {
        this.$emit("handleMediaSrc", val);
        this.$emit("change", val);
      },
    },
  },
  methods: {
    handleCrop(url) {
      if (typeof this.ratio === 'string') {
        this.ratioList = [this.ratio]
      } else if (this.ratio) {
        this.ratioList = this.ratio
      }
      // this.$store.dispatch("crop/openMediaCropDialog", { imgSrc: url });
      this.$store.dispatch('crop/openMediaCropDialog', {
        imgSrc: [url],
        ratio: this.ratioList[0],
        ratioList: this.ratioList,
        mediaKey: this.mediaKey,
        directly: true
      })
    },
    //确认
    handlePhoto(mediaList) {
      if (mediaList.length) {
        mediaList.forEach((element) => {
          if (element.type === "image" && typeof this.UploadType !== "string") {

            /* 裁剪完替换原图 */
            if (element.old_url !== undefined) {
              const oldI = this.mediaArr.findIndex(m => m.url === element.old_url)
              if (oldI !== -1) this.mediaArr.splice(oldI, 1, element)
              else this.mediaArr.push(element);
            } else this.mediaArr.push(element);
          } else if (
            element.type === "image" &&
            typeof this.UploadType === "string"
          ) {
            if (element.old_url !== undefined) {
              const oldI = this.mediaArr.findIndex(m => m === element.url)
              if (oldI !== -1) this.mediaArr.splice(oldI, 1, element.url)
              else this.mediaArr.push(element.url);
            } else this.mediaArr.push(element.url);
            console.log(typeof this.UploadType);
          } else {
            this.mediaArr.push({ ...element, ...element.config });
          }
        });
      }
      // console.log(this.mediaArr, mediaList);
      this.$emit('change')
    },
    //预览图片
    handlePreview(i) {
      this.$previewImage(this.srcList, i);
    },
    // 视频播放
    handlePlay(data) {
      this.$store.commit("media/setIsOpenMediaVideoDialog", true);
      this.$store.commit("media/setDialogDetail", data);
    },
    //删除
    deleteMediaSrc(i) {
      this.mediaArr.splice(i, 1);
      this.$emit('change')
    },
  },
  components: {
    CropDialog,
    draggable,
    MediaSelector,
    VideoDialog,
  },
};
</script>
<style lang="scss" scoped>
.photo-wall {
  line-height: 1;
  > .wall-content {
    display: flex;
    flex-wrap: wrap;
    .img-content {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      margin-right: 16px;
      margin-bottom: 16px;

      &.add.disabled {
        cursor: not-allowed;
      }

      .photo {
        vertical-align: middle;
        background-color: rgb(241, 241, 241);
      }
      .add,
      .bg-mask,
      .video-mask,
      .delete {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }
      //添加
      .add {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .add-content {
          font-size: 14px;
          line-height: 1;
          color: rgb(117, 117, 117);
          text-align: center;
          i {
            font-size: 25px;
          }
          .info {
            margin-top: 5px;
            color: inherit;
          }
        }
      }
      // 背景蒙层
      .bg-mask {
        height: 100%;
        color: #fff;
        text-align: center;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: all 0.3s ease;

        p {
          font-size: 20px;
        }

        p + p {
          margin-left: 15px;
        }
      }
      //删除
      .delete {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.6);
        transition: transform 0.2s ease-out;
        transform: translateY(30px);
      }

      .video-mask {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.4);

        .video-play {
          display: inline-block;
          width: 36px;
          height: 36px;
        }
      }

      &:hover:not(.disabled) {
        border-color: #3576ff !important;
        .add {
          .add-content {
            color: #3576ff;
          }
        }
        .bg-mask {
          opacity: 1;
        }
        .delete {
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
