var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photo-wall"},[_c('draggable',{staticClass:"wall-content",attrs:{"disabled":!_vm.draggable,"animation":"500","draggable":".draggable"},model:{value:(_vm.mediaArr),callback:function ($$v) {_vm.mediaArr=$$v},expression:"mediaArr"}},[_vm._l((_vm.mediaArr),function(media,index){return _c('div',{key:index,staticClass:"img-content draggable",style:({
          width: _vm.widthSize,
          height: _vm.heightSize,
          'border-radius': _vm.BorderRadius,
        })},[(typeof media === 'string')?_c('div',[_c('el-image',{staticClass:"photo",style:({
            width: _vm.widthSize,
            height: _vm.heightSize,
            'border-radius': _vm.BorderRadius,
          }),attrs:{"src":_vm.fixImageUrl(media),"fit":_vm.fit},on:{"click":function($event){return _vm.handlePreview(index)}}}),(_vm.showMask && !_vm.disabled)?_c('div',{staticClass:"bg-mask",style:({'line-height': _vm.heightSize})},[(_vm.showCut)?_c('p',{staticClass:"el-icon-scissors",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleCrop(media, index)}}}):_vm._e(),_c('p',{staticClass:"el-icon-zoom-in",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handlePreview(index)}}}),_c('p',{staticClass:"el-icon-delete",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteMediaSrc(index)}}})]):_vm._e()],1):_c('div',[(media.type === 'image')?_c('div',[_c('el-image',{staticClass:"photo",style:({
            width: _vm.widthSize,
            height: _vm.heightSize,
            'border-radius': _vm.BorderRadius,
          }),attrs:{"src":_vm.fixImageUrl(media.url),"fit":_vm.fit},on:{"click":function($event){return _vm.handlePreview(index)}}}),(_vm.showMask && !_vm.disabled)?_c('div',{staticClass:"bg-mask",style:({'line-height': _vm.heightSize})},[(_vm.showCut)?_c('p',{staticClass:"el-icon-scissors",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleCrop(media.url, index)}}}):_vm._e(),_c('p',{staticClass:"el-icon-zoom-in",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handlePreview(index)}}}),_c('p',{staticClass:"el-icon-delete",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteMediaSrc(index)}}})]):_vm._e()],1):_vm._e(),(media.type === 'video' || media.thumbnail)?_c('div',[_c('el-image',{staticClass:"photo",style:({
            width: _vm.widthSize,
            height: _vm.heightSize,
            'border-radius': _vm.BorderRadius,
          }),attrs:{"src":_vm.fixImageUrl(media.thumbnail),"fit":_vm.fit}}),_c('div',{staticClass:"video-mask",on:{"click":function($event){return _vm.handlePlay(media)}}},[_c('img',{staticClass:"video-play",style:({'line-height': _vm.heightSize}),attrs:{"src":require("@/base/assets/images/media/video-play@2x.png")}})]),(!_vm.disabled)?_c('div',{staticClass:"delete",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteMediaSrc(index)}}},[_vm._v(" 删除视频 ")]):_vm._e()],1):_vm._e()])])}),(_vm.limit && _vm.showAdd)?_c('div',[_c('div',{staticClass:"img-content add",class:{disabled: _vm.disabled},style:({
          width: _vm.widthSize,
          height: _vm.heightSize,
          'border-radius': _vm.BorderRadius,
          border: '1px dashed #dcdfe6',
          'margin-bottom': 0,
        }),on:{"click":function($event){!_vm.disabled ? (_vm.show = true) : ''}}},[_c('div',{staticClass:"add"},[_c('div',{staticClass:"add-content"},[_c('i',{staticClass:"el-icon-plus"}),_c('p',{staticClass:"info"},[_vm._v(_vm._s(_vm.addText))])])])]),_vm._t("info")],2):_vm._e()],2),_c('media-selector',{attrs:{"max":_vm.limit,"multiple":"","ratio":_vm.ratio,"type":_vm.UploadType},on:{"select":_vm.handlePhoto},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('VideoDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }